import React, { useState, useRef, useEffect } from 'react'
import { graphql, Link } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import classNames from 'classnames'
import Slider from 'react-slick'
import ModalHome from '../components/ModalHome'

import FormNewsletter from '../components/FormNewsletter'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const ns = `home-page`

const IndexPage = ({ data }) => {
  const { home, menu } = data
  const [isShown, setIsShown] = useState(false)
  const [mobileMenuState, setMobileMenuState] = useState(false)

  const sliderRef = useRef(null)

  useEffect(() => {
    document.body.classList.add('black-bg')
  }, [])

  useEffect(() => {}, [isShown])

  const rootClassnames = classNames({
    [`${ns}`]: true,
  })

  const toggleMobileMenu = () => {
    setMobileMenuState(prevMobileMenuState => !prevMobileMenuState)
    document.body.classList.toggle('modal-open')
  }

  const options = {
    arrows: false,
    dots: false,
    vertical: true,
    rtl: true,
    speed: 6000,
    autoplay: true,
    infinite: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 0.1,
    pauseOnHover: true,
  }

  return (
    <div className={rootClassnames}>
      <div className={`${ns}__header`}>
        <div
          className={`${ns}__header-container ${
            mobileMenuState ? 'is-active' : ''
          }`}
        >
          <div className={`logo ${mobileMenuState ? 'active' : ''}`}>
            <img className="light" src="/UM_logo_light.svg" alt="" />
            <img className="dark" src="/UM_logo.svg" alt="" />
          </div>
          <button
            className={`hamburger hamburger--collapse ${
              mobileMenuState ? 'is-active' : ''
            }`}
            type="button"
            onClick={toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </div>
        <div
          className={`${ns}__mobile-menu ${mobileMenuState ? 'active' : ''}`}
        >
          <div className={`${ns}__mobile-menu-container`}>
            {menu.frontmatter.menu.map(item => (
              <>
                {item.parent_menu_link.includes('http') ? (
                  <h2 className="title break">
                    <a
                      href={item.parent_menu_link}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.parent_menu_item}
                    </a>
                  </h2>
                ) : (
                  <h2 className="title break">
                    <Link to={item.parent_menu_link}>
                      {item.parent_menu_item}
                    </Link>
                  </h2>
                )}
                {item.sub_menu !== null
                  ? item.sub_menu.map(subitem => (
                      <Link to={subitem.sub_menu_link}>
                        {subitem.sub_menu_item}
                      </Link>
                    ))
                  : ''}
              </>
            ))}
            <div className={`${ns}__mobile-menu--footer`}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>
                {menu.frontmatter.mobile_menu_footer.content}
              </ReactMarkdown>
              <div className={`${ns}__mobile-menu--newsletter`}>
                <p>{menu.frontmatter.mobile_menu_footer.newsletter_text}</p>
                <FormNewsletter
                  tagList={menu.frontmatter.mobile_menu_footer.tags_list}
                  successMessage={
                    menu.frontmatter.mobile_menu_footer.success_message
                  }
                  errorMessage={
                    menu.frontmatter.mobile_menu_footer.error_message
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {home.frontmatter.welcome_message.show_message && (
        <div className={`${ns}__banner`}>
          <div className={`${ns}__banner--container`}>
            <h1>{home.frontmatter.welcome_message.message}</h1>
          </div>
        </div>
      )}
      <div
        className={`${ns}__container--text ${isShown ? 'active' : ''}`}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <nav>
          {menu.frontmatter.menu.map(item =>
            item.parent_menu_link.includes('http') ? (
              <a href={item.parent_menu_link} target="_blank" rel="noreferrer">
                {item.parent_menu_item}
              </a>
            ) : (
              <Link to={item.parent_menu_link}>{item.parent_menu_item}</Link>
            )
          )}
        </nav>
        <div className={`${ns}__container-submenu ${isShown ? 'active' : ''}`}>
          {menu.frontmatter.menu.map(item =>
            item.sub_menu !== null ? (
              <ul>
                {item.sub_menu.map(subitem => (
                  <li>
                    <Link to={subitem.sub_menu_link}>
                      {subitem.sub_menu_item}
                    </Link>
                  </li>
                ))}
              </ul>
            ) : (
              <ul />
            )
          )}
        </div>
      </div>
      <div className={`${ns}__address`}>
        <div className={`${ns}__address--container`}>
          <p>3508 W. Washington Blvd. Los Angeles, California 90018, &nbsp; </p>
          <p>
            <a href="mailto:info@theunderground.museum">
              info@theunderground.museum
            </a>
          </p>
        </div>
      </div>
      <div className={`${ns}__container--image`}>
        <Slider ref={sliderRef} {...options}>
          {home.frontmatter.slider.slides.map((slide, index) => (
            <div className={`${ns}__full-height`} key={index}>
              <img src={slide.slide.publicURL} alt="" />
            </div>
          ))}
        </Slider>
      </div>
      <ModalHome isOpen>
        <div className={`${ns}__notice-screen`}>
          <div className={`${ns}__notice-screen-content`}>
            <p>UM Family,</p>
            <p>
              It is with deep sadness that we announce the closing of the Noah
              Davis show and the end of Meg and Cristina’s tenure as
              Co-Directors of the museum. As you can imagine, these decisions
              have not been easy on any of us.
            </p>
            <p>
              As many of you know, our family (Kahlil, Onye, Faith, and myself)
              worked hard after Noah’s passing to steward the museum with help
              from countless artists, collaborators, and friends. We have been
              supported by amazing donors and a board hand-picked by Noah and
              our family. It has been an incredible journey.
            </p>
            <p>
              But it has also been deeply painful. As soon as Noah passed, each
              of us immediately jumped into the work of running the museum to
              realize his vision. As a result, we were not able to fully grieve
              his loss privately or take the time needed to heal. This was made
              all too clear when Noah’s paintings returned to the space for the
              first time since his passing. It was also evident in how hard it
              has been for our family to let go enough to allow Meg and Cristina
              to do their jobs. They have been extraordinary, and we are so
              grateful for the work they did with us.
            </p>
            <p>
              It is also undeniable that a great deal has changed since Noah’s
              passing in 2015: COVID, the movement for Black lives, and the
              growth of his legacy have impacted us all individually and as a
              family. These issues are affecting how each of us views the museum
              and our roles within it.
            </p>
            <p>
              For now, we ask that everyone give us the space and privacy needed
              to understand the future of the museum and to heal individually
              and collectively. We simply do not have any answers right now. So,
              we will also be closing the museum until further notice. During
              this period, we encourage you to engage with the incredible art
              spaces all over our beloved Los Angeles.
            </p>
            <p>
              Noah’s family and the UM are his greatest legacy and we are
              committed to doing all we can to enable them to flourish once
              again. Until then, it’s all love.
            </p>
            <p>
              With Gratitude, <br />
              Karon Davis <br />
              Co-Founder
            </p>
          </div>
        </div>
      </ModalHome>
    </div>
  )
}

export default IndexPage

export const indexPageQuery = graphql`
  query IndexPage($id: String!) {
    home: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        welcome_message {
          show_message
          message
        }
        slider {
          slides {
            slide {
              publicURL
            }
          }
        }
      }
    }
    menu: markdownRemark(frontmatter: { title: { eq: "Menu" } }) {
      frontmatter {
        menu {
          parent_menu_item
          parent_menu_link
          sub_menu {
            sub_menu_item
            sub_menu_link
          }
        }
        mobile_menu_footer {
          content
          error_message
          newsletter_text
          success_message
          tags_list
        }
      }
    }
  }
`
