import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import ReactModal from 'react-modal'

// Setup app element for react-modal
ReactModal.setAppElement('#___gatsby')

const ns = 'site-modal-home'

const Modal = ({
  children,
  variant,
  contentLabel = '',
  isOpen = false,
  onRequestClose,
  style,
}) => {
  const [openState, setIsOpenState] = useState(true)

  const rootClassnames = classNames({
    [`${ns}`]: true,
    [`${ns}--${variant}`]: variant,
  })

  const overlayClassName = classNames({
    [`${ns}__overlay`]: true,
    [`${ns}__overlay--${variant}`]: variant,
  })

  const handleCloseModal = () => {
    if (onRequestClose && typeof onRequestClose === 'function') {
      onRequestClose()
    } else {
      setIsOpenState(false)
    }
  }

  function closeModal() {
    setIsOpenState(false)
  }
  useEffect(() => {
    setIsOpenState(isOpen)
  }, [isOpen])

  return (
    <ReactModal
      contentLabel={contentLabel}
      className={rootClassnames}
      bodyOpenClassName={`${ns}--open`}
      overlayClassName={overlayClassName}
      isOpen={openState}
      onRequestClose={handleCloseModal}
      closeTimeoutMS={400}
      style={style}
    >
      <button type="button" className={`${ns}__close`} onClick={closeModal}>
        &#10005;
      </button>
      <div className={`${ns}__content-inner`}>{children}</div>
    </ReactModal>
  )
}

export default Modal
